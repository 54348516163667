/**
 * The `parseToUtc` function in TypeScript ensures that a given ISO date string is in UTC format by
 * appending 'Z' if necessary and returns a Date object.
 * @param {string} isoDate - The `isoDate` parameter is a string representing a date and time in ISO
 * 8601 format. This format includes the date, time, and time zone information. The function
 * `parseToUtc` takes this ISO 8601 formatted date string and converts it to a JavaScript `Date` object
 * @returns A Date object representing the parsed UTC date.
 */
export const parseToUtc = (isoDate: string): Date => {
	// Ensure date string ends with 'Z' to indicate UTC
	const formatedDate = isoDate.endsWith('Z') ? isoDate : `${isoDate}Z`;
	return new Date(formatedDate);
};
