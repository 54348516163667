import type { FC } from 'react';
import { isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { parseToUtc } from '@/utils/dates/parse-to-utc';

interface ArticleDateProps {
	className?: string;
	date: string;
}

export const ArticleDate: FC<ArticleDateProps> = ({ className = '', date }) => {
	const parsedDate = parseToUtc(date);

	if (!isValid(parsedDate)) {
		return null;
	}

	return (
		<time
			className={className}
			dateTime={isValid(parsedDate) ? parsedDate.toISOString() : undefined}
		>
			{formatInTimeZone(parsedDate, 'UTC', 'MMMM d, yyyy')}
		</time>
	);
};
